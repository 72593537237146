<template>
  <div ref="observeEl">
    <slot>
      <transition name="fade-fast">
        <Spinner v-if="isLoading" class="w-full mt-5"/>
      </transition>
    </slot>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from '@vueuse/core';

defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  'end-viewed',
]);

const observeEl = ref();
const targetIsVisible = ref(false);

watch(
  () => observeEl.value,
  (newEl) => {
    useIntersectionObserver(
      newEl,
      ([{ isIntersecting, },]) => {
        targetIsVisible.value = isIntersecting;
        if (
          targetIsVisible.value
        ) {
          emits('end-viewed');
        }
      }
    );
  }
);
</script>
